<template>
	<section class="main-content contentblock-section" :class="{ index: route.name.startsWith('index') }">
		<div v-for="block in contentblocks" :key="block.ID" class="contentblock-wrapper">
			<div class="row block-item">
				<div :class="block.color" class="columns column12 text-col align-center">
					<h2 v-if="block.title">{{ block.title }}</h2>
					<div v-parse-links v-html="block.content" />
				</div>
				<div class="columns column12 image-row-full">
					<div v-if="block.vimeoID">
						<div style="padding: 56.25% 0 0; position: relative">
							<iframe
								:src="
									'https://player.vimeo.com/video/' +
									block.vimeoID +
									'?h=48e73401ce&amp;loop=1&amp;muted=0&amp;badge=0&amp;autoplay=1&amp;controls=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
								"
								frameborder="0"
								allow="autoplay; fullscreen; picture-in-picture"
								allowfullscreen
								style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
								title="Aedes - Durgerdam"
							/>
						</div>
					</div>
					<div v-else>
						<picture>
							<source :srcset="block.imageWebp" type="image/webp" />
							<source :srcset="block.image" />
							<img :src="block.image" :alt="block.imageAlt" loading="lazy" />
						</picture>
					</div>
				</div>
				<div
					v-for="midSection in block.midSection"
					:key="midSection.ID"
					:class="block.color"
					class="columns column12 text-col extra-padding align-center"
				>
					<div v-parse-links v-html="midSection.content" />
				</div>
				<div class="image-row columns column12">
					<div v-for="image in block.bottomSection" :key="image.ID">
						<picture>
							<source :srcset="image.imageWebp" type="image/webp" />
							<source :srcset="image.image" />
							<img :src="image.image" :alt="image.imageAlt" loading="lazy" />
						</picture>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
const route = useRoute();

defineProps({
	contentblocks: { type: Array, default: () => [] },
});
</script>

<style lang="scss" scoped>
.contentblock-section {
	padding: 30px 0;

	&.index {
		.contentblock-wrapper {
			&:first-child {
				background: $footer-background-color;
				color: #fff;
				margin: 0;
				width: 100%;
				padding: 0 0 30px;

				h2 {
					color: #fff;
				}

				.button {
					color: #fff;
					border-color: #fff;

					&:hover {
						background: #fff;
						color: $footer-background-color;
						border-color: #fff;
					}
				}
			}
		}

		.text-col {
			padding: 25px 30px;
			margin: 25px auto;
		}
	}

	.row {
		max-width: 1900px;
	}

	.columns {
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		justify-content: center;
		float: none;

		&.image-row-full {
			display: block;
		}
	}

	.text-col {
		max-width: 1200px;
		margin: 0 auto 25px;
		padding: 0 30px;
		float: none;
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		justify-content: center;

		&.extra-padding {
			padding: 25px 30px;
			margin: 25px auto;
		}
	}

	.image-row {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;

		div {
			width: 33%;
		}

		img {
			width: 100%;
		}
	}
}

@media (max-width: 860px) {
	.contentblock-section {
		.image-row {
			flex-flow: column wrap;

			div {
				width: 100%;
				margin: 10px auto;
			}
		}
	}
}
</style>
